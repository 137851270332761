<template>
  <div
    id="app"
    class="h-100"
    :class="{
      'skinClasses': true,
      'persian-font-family': this.$i18n.locale === 'fa'
    }"
  >
    <b-overlay
      :show="globalLoading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <notice />
      <component :is="layout">
        <router-view />
      </component>
      <MessageModal />
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay } from 'bootstrap-vue'
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import ProfileApis from './services/apis/profile'
import notice from '@/views/components/notice.vue'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const MessageModal = () => import('@/layouts/components/MessageModal.vue')
const profile = new ProfileApis()

export default {
  components: {
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    MessageModal,
    BOverlay,
    notice,
  },
  data() {
    return {
      interval: null,
      layout: '',
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    route() {
      return this.$route
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    globalLoading() {
      return this.$store.state.auth.globalLoading
    },
    isFa() {
      return this.$i18n.locale
    },
    faviconUrl() {
      return process.env[`VUE_APP_FAVICON_${this.$i18n.locale.toUpperCase()}`]
    },
    loadingImageUrl() {
      return process.env[`VUE_APP_LOGO_1_${this.$i18n.locale.toUpperCase()}`]
    },
  },
  watch: {
    route: {
      handler() {
        if (this.$route.meta.layout === 'full') {
          this.layout = 'layout-full'
        } else {
          this.layout = `layout-${this.contentLayoutType}`
        }
      },
      deep: true,
    },
    '$store.state.auth.authenticated': {
      handler(value) {
        if (value) {
          this.startHeartbeat()
        } else {
          this.stopHeartbeat()
        }
      },
    },
    isFa(val) {
      if (this.$store.auth && this.$store.auth.authenticated) {
        profile.changeLang(val)
      }
      const el = document.body
      if (val === 'fa') {
        el.classList.add('persian-font-family')
      } else {
        el.classList.remove('persian-font-family')
      }
      this.setFavIcon()
    },
  },

  beforeDestroy() {
    this.stopHeartbeat()
  },
  created() {
    const el = document.body
    if (this.$i18n.locale === 'fa') {
      el.classList.add('persian-font-family')
    } else {
      el.classList.remove('persian-font-family')
    }
    this.startHeartbeat()
    this.setFavIcon()
  },

  beforeCreate() {
    $themeConfig.app.appLogoImage = process.env[`VUE_APP_LOGO_1_${this.$i18n.locale.toUpperCase()}`]

    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement,
      ).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement,
        ).value.slice(0, -2),
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  methods: {
    startHeartbeat() {
      this.stopHeartbeat()
      this.interval = setInterval(() => {
        if (document.hasFocus() && this.$store.state.auth.authenticated) {
          profile.heartBeat()
        }
      }, 10000)
    },
    stopHeartbeat() {
      clearInterval(this.interval)
    },
    setFavIcon() {
      const existingLink = document.querySelector('link[rel="icon"]')
      if (existingLink) {
        document.head.removeChild(existingLink)
      }
      const link = document.createElement('link')
      link.rel = 'icon'
      link.href = this.loadingImageUrl
      document.head.appendChild(link)
    },
  },

}
</script>
<style>
.persian-font-family * {
  font-family: Pelak !important;
}
</style>
